
import React from 'react'
//import Link from '../components/Link'
import MySEO from '../../components/seo'
import {Container, Box,Grid,Heading,Text} from 'theme-ui'
import Layout from '../../components/layout'
import {StaticImage} from 'gatsby-plugin-image'






export default function Colalaila(){
  return(
<Layout>   
<MySEO title="Colalaila" description="Veranstaltung vom K.O.M" />
<Container sx={{my:3,color:`text`}} >


   <Heading sx={{fontWeight:400,mt:3,mb:2,fontSize:[2,3,4]}}>Verluste. Jüdisches Leben im Mittelrheintal.</Heading>
  <Box sx={{m:0}}><h2 sx={{fontWeight:400,mt:3,mb:2,fontSize:[2]}}>Ein musikalischer Stolperstein mit der Klezmerband Colalaila</h2>
 
  </Box>


</Container>
<Grid  
 columns={[1,1,2,2]}
 gap={4}
>

<Text sx={{marginBottom:20}}>
Sehr gut besucht war zu unserer Freude der als Brückenveranstaltung 
realisierte „Musikalische Stolperstein“ zum Gedenken an das <strong>Hunsrücker Ehepaar Samuel & Johanna Baum</strong>. 
 Das Duo der Band <strong>Colalaila – Irith Gabriely (Klarinette) und Peter Przystaniak (Piano & Komposition)</strong> – führte
  die Gäste musikalisch und temperamentvoll durch den Abend, die Wahl-Hunsrückerin <strong>Leona Riemann</strong> las aus ihrer Kurzerzählung über Samuel Baum. <strong>Heiner Schneider und Christoph Pies</strong> erläuterten die Zusammenhänge rund um das wiederentdeckte Notenbuch.<br/> Die Veranstaltung des Kulturnetz Oberes Mittelrheintal K.O.M. bot
   in Kooperation mit der Kulturstiftung Hütte Oberwesel und dem Förderkreis Synagoge Laufersweiler den Besuchern ein berührendes und musikalisch hochklassiges Erlebnis. 
</Text>

<Box sx={{margin:'5px 0'}}>   
  <Grid gap={2} columns={[1,2]}>
    <Box>
    <StaticImage src="../../images/colaleila/1.jpg" alt="Colaleila" /> 
    </Box>
    <Box>
    <StaticImage src="../../images/colaleila/2.jpg" alt="Colaleila" /> 
    </Box>
    <Box>
    <StaticImage src="../../images/colaleila/3.jpg" alt="Colaleila" /> 
    </Box>
    <Box>
    <StaticImage src="../../images/colaleila/foto.jpg" alt="Colaleila" /> 
    </Box> 
  </Grid>
       </Box> 

       </Grid>
</Layout>

  )
}

